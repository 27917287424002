import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getCookie } from 'react-use-cookie';

import AppContext from '../context/app'
import * as Sentry from '@sentry/browser';

import { Button, Icon, Paper } from '@mui/material';
import Collapse from '@mui/material/Collapse';

import institutionService from '../services/institution';
import userService from '../services/user';

export default function Home(props) {
  const context = useContext(AppContext)
  const history = useHistory()
  const [systemLinks, setSystemLinks] = useState([])
  const [trainingLinks, setTrainingLinks] = useState([])
  const [showUnpurchased, setShowUnpurchased] = useState(false)
  const [unpurchasedLinks, setUnpurchasedLinks] = useState([])
  const [systemsCompletableComplete, setSystemsCompletableComplete] = useState()
  
  let tapAppClick = async ()=>{
    try{
      let tapAppLoginToken = await new userService().getTapAppLogin()
      if (tapAppLoginToken.token)
        window.location = `https://app.tapappsecurity.com/login/${tapAppLoginToken.token}`
      else
        window.location = 'https://app.tapappsecurity.com/'
    }
    catch (err) {
      console.log(err)
      window.location = 'https://app.tapappsecurity.com/'
    }
  }

  async function fetchData() {
    let [user, purchasedSystems, completedSections, institutions] = await Promise.all([
      new userService().get(),
      new institutionService().getInstitutionSystem(),
      new userService().getSectionComplete(),
      new institutionService().getInstitution()
    ])

    const isPolice = institutions.some( i => i.type === 'police')
    if (isPolice && (!user.enforcementOfficialPhone || !user.enforcementOfficialAddress ))
      history.push('/register');

    var systemLinks= [
      { priority: 1, key: "users", name: 'User Management', link: 'https://users.clpsconsultants.com', faClass: 'users', permission: ['userManagement'], purchased: true },
      { priority: 2, key: "emergencyPlan", name: 'Emergency Management Plan', link: 'https://plan.clpsconsultants.com', faClass: 'map', permission: ['emergencyPlan'], onlyTypes: ['business','school','worship'],  purchasedSystem: ['emergencyPlan'], learnMoreLink: 'https://www.clpsconsultants.com/emergency-management-plan-solution' },
      { priority: 3, key: "riskSurvey", name: 'Security Threat & Vulnerability Assessment', link: 'https://survey.clpsconsultants.com', faClass: 'id-badge', permission: ['riskSurvey'], onlyTypes: ['business','school', 'police', 'worship'],  purchasedSystem: ['riskSurvey','riskSurveyBusiness','riskSurveyProtection','riskSurveyResidential','riskSurveyTargeted','riskSurveyWorship','riskSurveyJewish'], learnMoreLink: 'https://www.clpsconsultants.com/security-audit-risk-survey-solution' },
      { priority: 4, key: "fireSurvey", name: 'Fire Inspection', link: 'https://survey.clpsconsultants.com', faClass: 'fire', permission: ['riskSurvey'], hideIfNotPurchased: true, onlyTypes: ['school'], purchasedSystem: ['fireSurvey'], learnMoreLink: 'https://www.clpsconsultants.com/fire-inspection-safety-survey-solution' },
      { priority: 5, key: "threatAssessment", name: 'Threat & Behavioral Assessment', link: 'https://threat.clpsconsultants.com/threatAssessment/list', faClass: 'exclamation-triangle',  permission: ['threatTeam','viewSelfHarm','viewAllThreats','reviewThreats'], onlyTypes: ['school','police','business','worship'], purchasedSystem: ['bullyAssessment', 'threatAssessment','assessmentSBTMT','employeeAssessment','assessmentCommunity'], learnMoreLink: 'https://www.clpsconsultants.com/threat-behavioral-assessment-solution' },
      { priority: 6, key: "trainingHPTM", name: 'Threat Assessment HPTM Training', link: 'https://training.clpsconsultants.com/#trainingHPTM', faClass: 'exclamation-triangle', permission: ['threatTeam','viewAllThreats'], onlyTypes: ['business','school','worship'], completeable: true, purchasedSystem: ['trainingHPTM'], learnMoreLink: 'https://www.clpsconsultants.com/digital-training-solutions' },
      { priority: 7, key: "studentReunification", name: 'Student Reunification Training', link: 'https://training.clpsconsultants.com/#studentReunification', faClass: 'user-friends', permission: ['training'], onlyTypes: ['school'], completeable: true, purchasedSystem: ['studentReunification'], learnMoreLink: 'https://www.clpsconsultants.com/digital-training-solutions' },
      { priority: 8, key: "bullyTraining", name: 'Bullying Intervention Training', link: 'https://training.clpsconsultants.com/#bullyTraining', faClass: 'school', permission: ['training'], onlyTypes: ['school'], completeable: true, purchasedSystem: ['bullyTraining'], learnMoreLink: 'https://www.clpsconsultants.com/digital-training-solutions' },
      { priority: 9, key: "threatTraining", name: 'Threat Assessment Training', link: 'https://training.clpsconsultants.com/#threatTraining', faClass: 'exclamation-circle', permission: ['training'],onlyTypes: ['business','school','worship'], completeable: true, purchasedSystem: ['threatTraining'], learnMoreLink: 'https://www.clpsconsultants.com/digital-training-solutions' },
      { priority: 10, key: "activeShooter", name: 'Active Shooter Training', link: 'https://training.clpsconsultants.com/#activeShooter', faClass: 'crosshairs', permission: ['training'],onlyTypes: ['business','school','worship'], completeable: true, purchasedSystem: ['activeShooter'], learnMoreLink: 'https://www.clpsconsultants.com/digital-training-solutions' },
      { priority: 11, key: "crisisIntervention", name: 'Critical Incident Management Training', link: 'https://training.clpsconsultants.com/#crisisIntervention', faClass: 'exclamation', permission: ['training'], onlyTypes: ['business','school','worship'], completeable: true, purchasedSystem: ['crisisIntervention'], learnMoreLink: 'https://www.clpsconsultants.com/digital-training-solutions' },
      { priority: 12, key: "workplaceTraining", name: 'Workplace Violence Prevention Training', link: 'https://training.clpsconsultants.com/#workplaceTraining', faClass: 'building', permission: ['threatTeam','viewAllThreats'], onlyTypes: ['business','school','worship'], completeable: true, purchasedSystem: ['workplaceTraining'], learnMoreLink: 'https://www.clpsconsultants.com/' },
      { priority: 98, key: "tapAppSecurity", name: 'Tap App Security', link: 'https://app.tapappsecurity.com', faClass: 'mobile', permission: ['userManagement'], onlyTypes: ['business','school','police','worship'], purchasedSystem: ['tapApp'], onClick: tapAppClick,  learnMoreLink: 'https://www.clpsconsultants.com/emergency-communication-solutions' },
    ]
    
    systemLinks.forEach( (systemLink) => {
      //have access to
      let purchasedSystem = purchasedSystems.find( (purchasedSystem) => { return !systemLink.purchasedSystem || systemLink.purchasedSystem.indexOf(purchasedSystem.system) > -1 })
      if (purchasedSystem) {
        systemLink.purchased = true;

        //completed all the sections
        if (systemLink.completeable && completedSections.filter( (completedSection) => { return completedSection.system === systemLink.key }).length === purchasedSystem.numberOfSections )
          systemLink.completed = true;
      }
    })

    //nothing left to complete
    let systemsCompletableComplete = user.hasCompletedTraining

    //check types
    systemLinks = systemLinks.filter( (systemLink) => { return !systemLink.onlyTypes || institutions.some( t => systemLink.onlyTypes.some( linkType => linkType === t.type ) ) })

    //check permissions
    systemLinks = systemLinks.filter( (systemLink) => { return systemLink.permission.some( (p) => !!user[p] ) })
        
    //if not purchased and hideIfNotPurchased
    systemLinks = systemLinks.filter( (systemLink) => { return !systemLink.hideIfNotPurchased || systemLink.purchased})

    //sort by priority    
    systemLinks = systemLinks.sort( (a, b) => { return a.priority - b.priority  } )


    //take out unpurchased
    let unpurchasedLinks = systemLinks.filter( sl => !sl.purchased )
    systemLinks = systemLinks.filter( sl => sl.purchased )

    //split training
    var trainingLinks = systemLinks.filter( sl => sl.completeable )
    systemLinks = systemLinks.filter( sl => !sl.completeable )

    setSystemLinks(systemLinks)
    setTrainingLinks(trainingLinks)
    setUnpurchasedLinks(unpurchasedLinks)

    setSystemsCompletableComplete(systemsCompletableComplete)
  }

  useEffect( () => {
    window.scroll(0,0)

     //load up data and goto default page
     if (!getCookie('clps')){
      window.location = "/login"
      return
    }

    if (!context.user)
      return

    //goto registry if missing any information
    if (!context.user.firstName || !context.user.lastName || !context.user.title || !context.user.hasPassword )
      history.push('/register');
    
    try {
      fetchData()
    }
    catch(err) {
      console.log(err);
      history.push('/login');
      Sentry.captureException(err);
    }
  },[context.user])

  let appClick = (systemLink) => {
    if (systemLink.onClick)
      systemLink.onClick()
    else if (!systemLink.purchased )
      window.location = `mailto:support@clpsconsultants.com?Subject=Purchase Inquiry ${systemLink.name}`
    else
      window.location = systemLink.link
  }

  let CLPSApp = (props) => {
    return (
      <section>
      <div className="system-link-icon-container"> 
        <i className={`system-link-icon fa fa-${props.systemLink.faClass}`}>
          { props.systemLink.completed ? 
            <i className='fa fa-check-circle completed' title="completed"></i>
          : null }
        </i>
      </div>
      <div className="system-link-name">
        {props.systemLink.name}
      </div>

      { props.systemLink.purchased && props.systemLink.completeable && !props.systemLink.completed ?                      
        <div>
          <div className="download-cert-container">
            <a href={props.systemLink.link}>
              <Button variant="outlined"><Icon className="fa fa-arrow-right" />Begin Training</Button>
            </a>
          </div> 
        </div>
      : null }
      
      { !props.systemLink.purchased ? 
        <div className="section">
           <a target="_blank" href={props.systemLink.learnMoreLink}>
              <Button variant="outlined"><Icon className="fa fa-book" />Learn More</Button>
            </a>
        </div> 
        : null }
      </section>
    )
  }

  return (
      <div id='home'>
        <Paper className="paper">
          <div className="flex-row system-links" >
            { systemLinks.map( (systemLink, index) => {
              return (
                <div className={`flex-col system-link-container purchased`} key={index}>
                  <div onClick={ (e) => appClick(systemLink) } className={`flex-col system-link purchased`}>
                    <CLPSApp systemLink={systemLink}></CLPSApp>
                  </div>
                </div>
              );
            })}
          </div>
        </Paper>

        { trainingLinks.length ?
        <Paper className="paper">
          { systemsCompletableComplete ?
            <div className="systems-completable-complete">
              Thank you! You have completed all required training.
              <span className="download-cert-container">
                <a download href='https://training.clpsconsultants.com/api/user/downloadCertificate'>
                  <Button variant="outlined"><Icon className="fa fa-certificate" /> Download Certificate</Button>
                </a>
              </span>
            </div>
          : null }

          <div className="flex-row system-links" >
            { trainingLinks.map( (trainingLink, index) => {
              let linkHref=trainingLink.purchased ? trainingLink.link : `#`
              return (
                <div className={`flex-col system-link-container purchased`} key={index}>
                  <a className={`flex-col system-link purchased`} href={linkHref}>
                    <CLPSApp systemLink={trainingLink}></CLPSApp>
                  </a>
                </div>
              );
            })}
          </div>
        </Paper>
        : null }

        { unpurchasedLinks.length ?
        <Paper>
          { !showUnpurchased ?
            <h2 className='showProductsLink' onClick={e => setShowUnpurchased(true)}><i className="fa fa-plus"></i> Show More Products</h2>
          : null }
            <Collapse in={showUnpurchased}>
              <div className="flex-row system-links" >
                { unpurchasedLinks.map( (unpurchasedLink, index) => {
                  return (
                    <div className={`flex-col system-link-container`} key={index}>
                      <div className={`flex-col system-link`}>
                        <CLPSApp systemLink={unpurchasedLink}></CLPSApp>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Collapse>
        </Paper>
        : null }
    </div>
  )
}
